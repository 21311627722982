<template>
    <modal
        class="create-board-modal"
        :adaptive="true"
        name="create-board-modal"
        height="auto"
        width="485"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="close-modal" @click="$modal.hide('create-board-modal')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="content">
            <h1>{{ isEdit ? "Edit" : "Create" }} Board</h1>
            <ValidationObserver v-slot="{ valid, handleSubmit }" slim>
                <form novalidate @submit.prevent="handleSubmit(isEdit ? editBoard : createBoard)">
                    <ValidationProvider
                        name="title"
                        rules="required"
                    >
                        <div class="form-group">
                            <label for="title">Title</label>
                            <input
                                id="title"
                                v-model="title"
                                type="text"
                                class="form-control"
                                maxlength="100"
                            >
                        </div>
                        <div class="board-privacy-switch">
                            <switches v-model="is_public" class="board-privacy" type-bold="false" />
                            <span>Publish as public</span>
                        </div>
                    </ValidationProvider>
                    <button :disabled="!valid" class="btn btn-primary">
                        {{ isEdit ? 'Save' : 'Create Board' }}
                    </button>
                </form>
            </ValidationObserver>
        </div>
    </modal>
</template>

<script>
import { mapState } from "vuex";
import Switches from "vue-switches";

export default {
    name: "CreateBoardModal",
    components: {
        Switches
    },
    data() {
        return {
            title: "",
            is_public: true,
            memoToSaveId: null,
            isEdit: false,
            boardId: null
        };
    },
    computed: {
        ...mapState({
            userId: state => state.User.data.id
        })
    },
    methods: {
        beforeOpen(event) {
            if (event.params?.memoToSaveId) {
                this.memoToSaveId = event.params?.memoToSaveId;
            }

            if (event.params?.isEdit) {
                this.title = event.params?.boardToEdit.title;
                this.is_public = event.params?.boardToEdit.is_public;
                this.boardId = event.params?.boardToEdit.id;
                this.isEdit = true;
            }
        },
        beforeClose() {
            this.title = "";
            this.is_public = true;
            this.memoToSaveId = null;
            this.boardId = null;
            this.isEdit = false;
        },
        createBoard() {
            axios.post(`/users/${this.userId}/lists`, { list_name: this.title, is_public : Number(this.is_public) }).then(({ data }) => {
                if (this.memoToSaveId) {
                    this.saveMemoToBoard(this.memoToSaveId, data.id, this.title);
                } else {
                    this.$notify({
                        group: "app-notifications",
                        type: "success",
                        text: `Published! Your ${this.title} Board is now live on Memo'd. Enjoy!`,
                        duration: 3000
                    });
                }
                this.$emit("created-board", data);
            }).catch((error) => {
                this.$notify({
                    group: "app-notifications",
                    type: "error",
                    text: `Sorry, unable to create the ${this.title} Board right now. Please try again later.`,
                    duration: 3000
                });
                console.log(error.response.data.errors.message);
            }).finally(() => {
                this.$modal.hide("create-board-modal");
            });
        },
        saveMemoToBoard(memoToSaveId, boardId, boardTitle) {
            this.$store.dispatch("Boards/toggleMemoToBoard", { memoId: memoToSaveId, boardId, boardTitle });
        },
        editBoard() {
            axios.put(`boards/${this.boardId}`, { list_name: this.title, is_public : Number(this.is_public) }).then(({ data }) => {
                this.$notify({
                    group: "app-notifications",
                    type: "success",
                    text: `Your board ${this.title} was edited successfully`,
                    duration: 3000
                });
                this.$emit("edited-board", data);
            }).catch((error) => {
                this.$notify({
                    group: "app-notifications",
                    type: "error",
                    text: "Unable to edit board, try again later.",
                    duration: 3000
                });
                console.log(error.response.data.errors.message);
            }).finally(() => {
                this.$modal.hide("create-board-modal");
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.create-board-modal {
    z-index: 99999;

    .close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;

            img {
                width: 15px;
            }
        }
    }

    /deep/ .vm--modal {
        background-color: #1C1C1E;
        border-radius: 10px;
        box-shadow: none;

        .content {
            padding: 20px 40px;
            display: flex;
            flex-direction: column;

            h1 {
                text-align: center;
                margin-bottom: 75px;
            }

            form {
                display: flex;
                flex-direction: column;

                button {
                    margin-left: auto;
                    margin-right: auto;
                }

                padding-bottom: 20px;

                .board-privacy-switch {
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;

                    .board-privacy {
                        &.vue-switcher {
                            div {
                                &:after {
                                    background-image: url("~@assets/img/icons/locked.svg");
                                }
                            }
                        }
                    }

                    span {
                        margin-left: 10px;
                        color: rgba(235, 235, 245, 0.6);
                    }
                }
            }
        }
    }
}
</style>
